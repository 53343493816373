import React from "react";
import { useNavigate } from 'react-router-dom';
import { BtnSubmit } from './BtnSubmit';

export const DeleteDataBtn = () => {
  const navigate = useNavigate();
  const handleClearDataAndRedirect = () => {
    localStorage.clear();
    navigate('/');
  };
  return <BtnSubmit class="warning" onClick={handleClearDataAndRedirect} type="submit">Usuń wszystkie dane</BtnSubmit>
};