import './SumCountKcal.scss'
import React from "react";

export const SumCountKcal = props => {

  return <>
    <div className="flex-row">
      <h2>Twoje dzienne zapotrzeboanie kaloryczne dla: {props.viewValue}</h2>
    </div>
    <div className="table-grid">
      <div className="table-grid__name">Wagi</div>
      <div className="table-grid__value">wartość kg</div>
      <div className="table-grid__name">Aktywności</div>
      <div className="table-grid__value">wartość</div>

      <div className="table-grid__name">Cel</div>
      <div className="table-grid__value">wartość</div>

    </div>
  </>

}