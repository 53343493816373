import './App.scss';
import React, { useState, useEffect } from 'react';
import { HomeView } from './views/HomeView';
import { StartView } from './views/StartView';
import { InfoView } from './views/InfoView';
import { Route, Routes, useNavigate } from 'react-router-dom';

export default function App() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    userName: '',
    userGender: '',
  });
  const [zerCaloric, setZeroCaloric] = useState(null);

  useEffect(() => {
    const userName = localStorage.getItem('userName');
    const userGender = localStorage.getItem('userGender');

    const isKnown = userName && userGender;
    const currentPath = window.location.pathname;

    if (isKnown && currentPath === '/') {
      setUserData({ userName, userGender });
      navigate('/home');
    } else if (!isKnown && currentPath !== '/') {
      navigate('/');
    }
  }, [navigate]);

  return (
    <main className='main-box'>
      <Routes>
        <Route path="/" element={<StartView />} />
        <Route path="/home"
          element={
            <HomeView
              userData={userData}
              setZeroCaloric={setZeroCaloric}
            />
          }
        />
        <Route path="/information"
          element={
            <InfoView
              userData={userData}
              zerCaloric={zerCaloric}
            />
          }
        />
      </Routes>
    </main>
  );
}
