import './HomeView.scss';

import React, { useState } from 'react';

import { TitleBox } from '../elements/TitleBox';
import { Input } from '../elements/Input';
import { BtnSubmit } from '../elements/BtnSubmit';
import { useNavigate } from 'react-router-dom';
// import { SumCountKcal } from '../elements/SumCountKcal';
// import { DeleteDataBtn } from '../elements/DeleteDataBtn';

export const HomeView = ({ userData, setZeroCaloric }) => {
  const navigate = useNavigate();
  const { userName, userGender } = userData;

  const initializeFormValues = () => {
    return {
      weight: localStorage.getItem('userWeight') || null,
      radioBtnActivity: localStorage.getItem('userActivity') || null,
      radioBtnSex: userGender || localStorage.getItem('userGender') || '', // Ustaw wartości na podstawie userData lub localStorage
      radioBtnCaloricGoal: localStorage.getItem('userGoal') || null,
    };
  };

  const [formValues, setFormValues] = useState(initializeFormValues); // Inicjalizacja stanu formularza
  // const [zeroCaloric, setZeroCaloric] = useState(null);

  const activityLevels = [
    { value: '1.2', label: '1' },
    { value: '1.3', label: '2' },
    { value: '1.4', label: '3' },
    { value: '1.5', label: '4' },
    { value: '1.6', label: '5' },
    { value: '1.7', label: '6' }
  ];

  const caloricGoal = [
    { value: '500', label: 'redukcja' },
    { value: '300', label: 'lekka redukcja' },
    { value: '0', label: 'zero kaloryczne' },
    { value: '-300', label: 'budowanie masy' }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('userGender', formValues.radioBtnSex);
    localStorage.setItem('userWeight', formValues.weight);
    localStorage.setItem('userActivity', formValues.radioBtnActivity);
    localStorage.setItem('userActivity', formValues.radioBtnCaloricGoal);

    // Pobieranie wartości z formValues
    const weight = parseFloat(formValues.weight); // przekształcenie ciężaru na liczbę zmiennoprzecinkową
    const activityLevel = parseFloat(formValues.radioBtnActivity); // przekształcenie poziomu aktywności na liczbę zmiennoprzecinkową
    const sexFactor = parseFloat(formValues.radioBtnSex); // przekształcenie współczynnika płci na liczbę zmiennoprzecinkową
    const baseCaloricNeeds = (weight * activityLevel * sexFactor).toFixed(0);
    const caloricGoalValue = parseFloat(formValues.radioBtnCaloricGoal);
    const adjustedCaloricNeeds = (parseFloat(baseCaloricNeeds) - caloricGoalValue).toString();
    // Obliczenie zapotrzebowania kalorycznego
    setZeroCaloric(baseCaloricNeeds);


    navigate('/information', { state: { adjustedCaloricNeeds } });
  };

  return (
    <>
      <TitleBox>
        <h1>Cześć <span className="name_value">{userName}</span></h1>
        <h2>Wypełnij formularz</h2>
      </ TitleBox>
      <form
        className="form-box form-caloric-needs"
        onSubmit={handleSubmit}>
        <div className='form-line flex-row'>
          <h3>Podaj jaką masz obecnie wagę ciała: </h3>
          <Input
            type="number"
            id="weightBox"
            name="weight"
            value={formValues.weight}
            onChange={handleChange} >
            Podaj samą cyfrę(kg)
          </Input>
        </div>
        <div className='form-line flex-row column'>
          <div><h3>Jaka jest Twoja dzienna aktywność?<br />
            <small>Uwzględnij w tym rodzaj pracy oraz aktywność fizyczną (np. trening), gdzie:
              <br />- 1: praca biurowa i/lub brak treningów,
              <br />- 2: praca biurowa + 1-2h treningów tygodniowo,
              <br />- 3: praca biurowa + 3-4h treningów tygodniowo,
              <br />- 4: praca biurowa/fizyczna + 3 treningi tygodniowo,
              <br />- 5: praca fizyczna + 4-5 treningów tygodniowo,
              <br />- 6: praca fizyczna i/lub 5-7 treningów w tygodniu</small></h3></div>
          <div className='bigbox-radio-btns box-flex row jc-space-between'>
            {activityLevels.map((activity, index) => (
              <Input
                key={index}
                type="radio"
                id={`activity-${activity.value}`}
                name="radioBtnActivity"
                value={activity.value}
                checked={formValues.radioBtnActivity === activity.value}
                onChange={handleChange}
              >{`${activity.label}`}
              </Input>
            ))}
          </div>

        </div>
        <div className='form-line flex-row'>
          <h3>Czujesz się:</h3>
          <div className="box-btn__radio flex-row row no-padd">
            <div className="box-btn__input">
              <Input
                type="radio"
                id="female"
                name="radioBtnSex"
                value="22"
                checked={formValues.radioBtnSex === '22'}
                onChange={handleChange} >
                Kobietą
              </Input>
            </div>
            <div className="box-btn__input">
              <Input
                type="radio"
                id="male"
                name="radioBtnSex"
                value="24"
                checked={formValues.radioBtnSex === '24'}
                onChange={handleChange}>Mężczyzną</Input>
            </div>
          </div>
        </div>
        <div className='form-line flex-row column'>
          <div>
            <h3>Co chcesz osiągnąć?</h3>
          </div>
          <div className='bigbox-radio-btns box-flex row jc-space-between'>
            {caloricGoal.map((goal, index) => (
              <Input
                key={index}
                type="radio"
                id={`goal-${index}`}
                name="radioBtnCaloricGoal"
                value={goal.value}
                checked={formValues.radioBtnCaloricGoal === goal.value}
                onChange={handleChange}
              >{`${goal.label}`}
              </Input>
            ))}
          </div>
        </div>
        <div className="form-line flex-row jc-center">
          <BtnSubmit class="big" type="submit">Oblicz</BtnSubmit>
        </div>
      </form>
      {/* <div className="flex-row jc-center">
        <SumCountKcal viewValue={caloricNeeds} />
        <DeleteDataBtn />
      </div> */}
    </>
  );
}
