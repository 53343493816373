import './Input.scss';

export const Input = ({ type, id, name, value, onChange, checked, children }) => {
  return (
    <label className="Input" htmlFor={id}>

      {type === 'radio' ? (
        <>

          <input
            type="radio"
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            checked={checked}
          />
          <span className="input-marker">
            {children}
          </span>
        </>
      ) : (
        <input
          placeholder={children}
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
        />
      )}
    </label>
  );
};

