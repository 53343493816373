import './BtnSubmit.scss';

export const BtnSubmit = ({ type, onClick, children, class: additionalClass }) => {
  const classNames = `btn${additionalClass ? ` ${additionalClass}` : ''}`;
  return (
    <button className={classNames} onClick={onClick} type={type}>
      {children}
    </button>
  );
};

