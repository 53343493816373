import './StartView.scss';

import React, { useState } from "react";
import { TitleBox } from '../elements/TitleBox';
import { Input } from '../elements/Input';
import { BtnSubmit } from '../elements/BtnSubmit';


export const StartView = () => {
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleSubmit = (event) => {

    localStorage.setItem('userName', name);
    localStorage.setItem('userGender', gender);
    // Możesz tutaj przekierować użytkownika do głównej aplikacji
  };
  return (
    <>

      <TitleBox>
        <h1>Cześć nieznajomy!</h1>
        <h3>Jestem aplikacją, która obliczy Twoje dzienne zapotrzebowanie kaloryczne oraz podpowie ile makroskładników powinieneś jeść.</h3>
      </ TitleBox>

      <form className="form-box" onSubmit={handleSubmit}>
        <div className="form-line flex-row ai-center">

          <h3>Napisz, jak się nazywasz: </h3>
          <Input
            type="text"
            value={name}
            onChange={handleNameChange}
          >Wpisz swoje imię</Input>
        </div>
        <div className="form-line flex-row ai-center ">
          <h3>Oraz zaznacz kim się czujesz:</h3>

          <div className="box-btn__radio flex-row row">
            <div className="box-btn__input">
              <Input
                type="radio"
                value="22"
                checked={gender === '22'}
                onChange={handleGenderChange}
              >Kobietą
              </Input>
            </div>
            <div className="box-btn__input">
              <Input
                type="radio"
                value="24"
                checked={gender === '24'}
                onChange={handleGenderChange}
              >Mężczyzną
              </Input>
            </div>
          </div>
        </div>
        <div className="form-line flex-row jc-center">
          <BtnSubmit class="big" type="submit">Zaczynajmy!</BtnSubmit>
        </div>

      </form >
    </>
  );
}