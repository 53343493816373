import React, { useState } from 'react';
import { TitleBox } from '../elements/TitleBox';
import { SumCountKcal } from '../elements/SumCountKcal';
import { DeleteDataBtn } from '../elements/DeleteDataBtn';
import { useLocation } from 'react-router-dom';

export const InfoView = ({ userData, zerCaloric }) => {
  const location = useLocation()
  const { userName } = userData;
  const { adjustedCaloricNeeds } = location.state || {};
  return <>
    <TitleBox>
      <h1><span className="name_value">{userName}</span></h1>
    </ TitleBox>


    <SumCountKcal viewValue={zerCaloric} />
    <p>{adjustedCaloricNeeds}</p>

    <div className="flex-row jc-center">
      <DeleteDataBtn />
    </div>
  </>
};